import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { ApiService } from './services/api.service';
import { AccountService } from './services/account.service';
import { AuthService } from './services/auth.service';
import { StripeService } from './services/stripe.service';
import { AdminService } from './services/admin.service';

import { GlobalForComponents } from './components/global-for-components';

import { AppComponent } from './app.component';
import { AccountFidelityCreditComponent } from './components/account/account-fidelity-credit/account-fidelity-credit.component';
import { AccountPrivilegeComponent } from './components/account/account-privilege/account-privilege.component';
import { AccountOffersComponent } from './components/account/account-offers/account-offers.component';
import { AccountEditPersonalInfoComponent } from './components/account/account-edit-personal-info/account-edit-personal-info.component';
import { AccountFavoriteHairsalonComponent } from './components/account/account-favorite-hairsalon/account-favorite-hairsalon.component';
import { AccountSignOutComponent } from './components/account/account-sign-out/account-sign-out.component';
import { AccountLoginComponent } from './components/account/account-login/account-login.component';
import { AccountRegisterComponent } from './components/account/account-register/account-register.component';
import { AccountComponent } from './components/account/account/account.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { CreditComponent } from './components/credit/credit.component';
import { SearchUserForValidateComponent } from './components/search-user-for-validate/search-user-for-validate.component';
import { UserValidationComponent } from './components/user-validation/user-validation.component';
import { SubscribeComponent } from './components/account/subscribe/subscribe.component';
import { AccountSubscriptionsComponent } from './components/account/account-subscriptions/account-subscriptions.component';
import { AccountPaymentMethodsComponent } from './components/account/account-payment-methods/account-payment-methods.component';
import { CgvComponent } from './components/cgv/cgv.component';
import { LostPasswordComponent } from './components/lost-password/lost-password.component';
import { ConfirmChangeMobilePhoneComponent } from './components/account/confirm-change-mobile-phone/confirm-change-mobile-phone.component';
import { WaitAdminForUserValidationComponent } from './components/wait-admin-for-user-validation/wait-admin-for-user-validation.component';
import { UsersValidationComponent } from './components/admin/users-validation/users-validation.component';
import { ShowNewHairdressingcardComponent } from './components/show-new-hairdressingcard/show-new-hairdressingcard.component';
import { GeneratePublicityDocumentComponent } from './components/ivoire-publicity-document/generate-publicity-document/generate-publicity-document.component';

const appRoutes: Routes = [
	{
		path: '',
		component: SearchUserForValidateComponent
	},
	{
		path: 'rechercher-pour-valider',
		component: SearchUserForValidateComponent
	},
	{
		path: 'se-connecter',
		component: AccountLoginComponent
	},
	{
		path: 'se-connecter/:identifier',
		component: AccountLoginComponent
	},
	{
		path: 'valider/:user_id',
		component: UserValidationComponent
	},
	{
		path: 'inscription',
		component: UserValidationComponent
	},
	{
		path: 'cgv',
		component: CgvComponent
	},
	{
		path: 'oublie-mot-de-passe',
		component: LostPasswordComponent
	},
	{
		path: 'votre-carte-coiffure/:hairdressingcard',
		component: ShowNewHairdressingcardComponent
	},
	{
		path: 'attente_admin/:user_id',
		component: WaitAdminForUserValidationComponent
	},
	{
		path: 'compte/souscrire',
		component: SubscribeComponent
	},
	{
		path: 'compte/mes-abonnements',
		component: AccountSubscriptionsComponent
	},
	{
		path: 'compte/mes-abonnements/:stripe_session_id',
		component: AccountSubscriptionsComponent
	},
	{
		path: 'compte/mes-moyens-de-paiement',
		component: AccountPaymentMethodsComponent
	},
	{
		path: 'compte/mon-credit-fidelite',
		component: AccountFidelityCreditComponent
	},
	{
		path: 'compte/mes-privileges',
		component: AccountPrivilegeComponent
	},
	{
		path: 'compte/mes-offres',
		component: AccountFidelityCreditComponent
	},
	{
		path: 'compte/editer',
		component: AccountEditPersonalInfoComponent
	},
	{
		path: 'compte/confirmer-telephone-mobile',
		component: ConfirmChangeMobilePhoneComponent
	},
	{
		path: 'compte/mon-salon-favori',
		component: AccountFavoriteHairsalonComponent
	},
	{
		path: 'compte/se-deconnecter',
		component: AccountSignOutComponent
	},
	{
		path: 'compte',
		component: AccountComponent
	},
	{
		path: 'admin/utilisateurs-a-valider',
		component: UsersValidationComponent
	},
	{
		path: 'confirmer_mail/:code',
		component: ConfirmEmailComponent
	},
	{
		path : 'credits',
		component: CreditComponent
	},
	{
		path: 'generer-documents-publicitaires',
		component: GeneratePublicityDocumentComponent
	},
];

@NgModule({
	declarations: [
		AppComponent,
		AccountFidelityCreditComponent,
		AccountPrivilegeComponent,
		AccountOffersComponent,
		AccountEditPersonalInfoComponent,
		AccountFavoriteHairsalonComponent,
		AccountLoginComponent,
		AccountRegisterComponent,
		AccountSignOutComponent,
		AccountComponent,
		ConfirmEmailComponent,
		CreditComponent,
		SearchUserForValidateComponent,
		UserValidationComponent,
		SubscribeComponent,
		AccountSubscriptionsComponent,
		AccountPaymentMethodsComponent,
		CgvComponent,
		LostPasswordComponent,
		ConfirmChangeMobilePhoneComponent,
		WaitAdminForUserValidationComponent,
		UsersValidationComponent,
		ShowNewHairdressingcardComponent,
		GeneratePublicityDocumentComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'cartecoiffure' }),
		RouterModule.forRoot(
			appRoutes,
			{ enableTracing: false } // <-- debugging purposes only
		),
		FormsModule,
		HttpModule
	],
	providers: [RouterModule, GlobalForComponents, ApiService, AuthService, AccountService, StripeService, AdminService],
	bootstrap: [AppComponent]
})
export class AppModule { }
