import { Component, OnInit } from '@angular/core';
import { GlobalForComponents } from '../../global-for-components';
import { StripeService } from '../../../services/stripe.service';
import * as moment from 'moment';
declare var jQuery: any;

declare var Stripe;
const stripe = Stripe('pk_live_ArWFhYv1MiVOxtdjO17uJj0Z00PPKJ9VTo');
const elements = stripe.elements();

@Component({
	selector: 'app-account-payment-methods',
	templateUrl: './account-payment-methods.component.html',
	styleUrls: ['./account-payment-methods.component.css']
})
export class AccountPaymentMethodsComponent implements OnInit {

	userPaymentMethods: Array<any> = [];
	buttonDisabled: boolean = false;
	cardElement: any;
	cardholderName: string;
	billingDetails: any = {
		address: {
			city: null,
			country: 'FR',
			line1: null,
			line2: null,
			postal_code: null,
			state: null,
		},
		email: null,
		name: null,
		phone: null
	};
	customerName: any = {
		first: null,
		last: null
	};

	constructor(public g: GlobalForComponents, private stripeService: StripeService) { }

	ngOnInit() {
		this.cardElement = elements.create('card');
		this.cardElement.mount('#card-element');
		this.getUserPaymentMethods();
	}

	getUserPaymentMethods() {
		const promise: Promise<Object> = this.stripeService.paymentMethods();
		promise.then((data: any) => {
			this.userPaymentMethods = data.results.payment_methods;
		}, (error) => {
			this.g.setMessage(false, error.message);
		});
	}

	async add() {
		this.buttonDisabled = true;

		if (this.billingDetails.name && this.billingDetails.address.line1 && this.billingDetails.address.city && this.billingDetails.address.postal_code) {
			if (!this.billingDetails.email) { this.billingDetails.email = null; }
			if (!this.billingDetails.phone) { this.billingDetails.phone = null; }
			const parent = this;
			const momentt = moment;
			stripe.createPaymentMethod({
				type: 'card',
				card: this.cardElement,
				billing_details: this.billingDetails,
			}).then(function (result) {
				if (result.error) {
					parent.buttonDisabled = false;
					parent.g.setMessage(false, result.error.message);
				} else {
					const card = result.paymentMethod.card;
					const cardExpirationDate = momentt().year(card.exp_year).month(card.exp_month).date(25);
					parent.buttonDisabled = false;
					if (cardExpirationDate.diff(momentt().date(25).add(7, 'months'), 'months') >= 0) {
						const promise = parent.stripeService.addPaymentMethod(result.paymentMethod.id);
						promise.then(
							(data: any) => {
								parent.g.setMessage(true, 'Moyen de paiement ajouté ! Pour l\'utiliser lors de vos prochains prélèvement automatique (dans le cas d\'un abonnement), merci de le définir par défaut.');
								parent.billingDetails = {
									address: {
										city: null,
										country: 'FR',
										line1: null,
										line2: null,
										postal_code: null,
										state: null,
									},
									email: null,
									name: null,
									phone: null
								};
								parent.getUserPaymentMethods();
								parent.goToTop();
							},
							error => {
								parent.buttonDisabled = false;
								parent.g.setMessage(false, error.message);
							}
						);
					} else {
						parent.buttonDisabled = false;
						parent.g.setMessage(false, 'Votre carte arrive à expiration dans moins de 7 mois, ce qui va à l\'encontre de la durée d\'engagement d\'un potentiel abonnement.');
					}
				}
			});
		} else {
			this.buttonDisabled = false;
			this.g.setMessage(false, 'Veuillez remplir tout les champs obligatoires');
		}
	}

	setDefault(paymentMethodId: string) {
		const promise = this.stripeService.setDefaultPaymentMethod(paymentMethodId);
		promise.then(
			(data: any) => {
				this.getUserPaymentMethods();
			},
			error => {
				this.g.setMessage(false, error.message);
			}
		);
	}

	goToTop() {
		jQuery('html,body').scrollTop(150);
	}

}
