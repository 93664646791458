import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalForComponents } from '../global-for-components';
const JsBarcode = require('jsbarcode');

@Component({
	selector: 'app-show-new-hairdressingcard',
	templateUrl: './show-new-hairdressingcard.component.html',
	styleUrls: ['./show-new-hairdressingcard.component.css']
})
export class ShowNewHairdressingcardComponent implements OnInit {

	hairdressingCard: string;

	constructor(
		private route: ActivatedRoute,
		public g: GlobalForComponents
	) { }

	ngOnInit() {
		this.route.params.subscribe(async params => {
			this.hairdressingCard = params['hairdressingcard'];
			setTimeout(() => {
				JsBarcode('#code128', this.hairdressingCard);
			}, 500);
		});
	}

}
