import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
const GlobalVar = require('../../../global_vars');

@Injectable()
export class AdminService {

	url: String;
	token: String;

	constructor(private http: Http) {
		this.url = GlobalVar.getHost() + '/api/admin';

		if (typeof (localStorage) !== 'undefined') {
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token');
			}
		}
	}

	usersValidation(): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/users-validation', { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	userValidation(userId: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/user-validation/' + userId, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	declineUserValidation(userId: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/user-validation/decline/' + userId, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

}
