import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../services/account.service';
import { GlobalForComponents } from '../../global-for-components';

@Component({
	selector: 'app-confirm-change-mobile-phone',
	templateUrl: './confirm-change-mobile-phone.component.html',
	styleUrls: ['./confirm-change-mobile-phone.component.css']
})
export class ConfirmChangeMobilePhoneComponent implements OnInit {

	code: string;

	constructor(private accountService: AccountService, public g: GlobalForComponents) {
	}

	ngOnInit() {
	}

	change() {
		if (this.code) {
			const promise: Promise<Object> = this.accountService.confirmMobilePhone(this.code);
			promise.then((data: any) => {
				this.g.setMessage(true, 'Votre numéro de téléphone a été mis à jour');
				setTimeout(() => {
					location.href = '/compte';
				}, 3000);
			}, (error) => {
				this.g.setMessage(false, error.message);
			});
		} else {
			this.g.setMessage(false, 'Veuillez entrer le code reçu par SMS');
		}
	}

}
