import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { GlobalForComponents } from '../../global-for-components';

@Component({
	selector: 'app-account-login',
	templateUrl: './account-login.component.html',
	styleUrls: ['./account-login.component.css']
})
export class AccountLoginComponent implements OnInit {

	identifier: string;
	password: string;

	constructor(private authService: AuthService, public g: GlobalForComponents, private route: ActivatedRoute) {
		if (typeof (localStorage) !== 'undefined') {
			if (localStorage.getItem('token')) {
				location.href = '/compte';
			}
		}
	}

	ngOnInit() {
		this.route.params.subscribe(async params => {
			this.identifier = params['identifier'];
		});
	}

	login() {
		const promise: Promise<Object> = this.authService.login(this.identifier, this.password);
		promise.then((data: any) => {
			const results = data.results;
			localStorage.setItem('token', results.token);
			location.href = '/compte';
		}, (error) => {
			this.g.setMessage(false, error.message);
		});
	}

}
