module.exports = class GlobalVar {
	constructor() { }

	static getEnvironementType() {
		return 'production'; // localhost/development(test)/production
	}

	static getPort() {
		switch (this.getEnvironementType()) {
			case 'localhost':
				return 4003;
			case 'production':
				return 80;
			case 'development':
				return 80;
			default:
				return 4002;
		}
	}

	static getHost() {
		switch (this.getEnvironementType()) {
			case 'localhost':
				return 'http://localhost:' + this.getPort();
			case 'production':
				return 'https://mon-compte.ma-carte-coiffure.fr';
			case 'development':
				return 'https://mon-compte.ma-carte-coiffure.fr';
			default:
				return 'https://mon-compte.ma-carte-coiffure.fr';
		}
	}

	static getBDDHost() {
		return 'mongodb://5.196.80.55:8098';
	}

	static getOptions() {
		const options = {
			db: { native_parser: true },
			server: { poolSize: 5 },
			replset: { rs_name: 'myReplicaSetName' },
			user: 'jca',
			pass: 'm4phgd9smk6tpwjhu2'
		};

		switch (this.getEnvironementType()) {
			case 'localhost':
			case 'development':
				options.pass = '8y4rmtH3RrkD';
				break;
			default:
				break;
		}

		return options;
	}

	static getBDDName() {
		switch (this.getEnvironementType()) {
			case 'localhost':
			case 'development':
				return 'moncompte-macartecoiffure-dev';
			default:
				return 'moncompte-macartecoiffure';
		}
	}

	static progressAubryAcademieHost() {
		switch (this.getEnvironementType()) {
			case 'localhost':
			case 'development':
				return 'http://progressaubryacademie.com';
			case 'production':
				return 'http://progressaubryacademie.com';
			default:
				return 'http://progressaubryacademie.com';
		}
	}

	static progresAubryAcademieUser() {
		return {
			email: 'admin@jca-community.fr',
			password: 'udGwGNwP'
		};
	}

	static ovhSmsUser() {
		return {
			appKey: 'pcXzdLElKdeyslFf',
			appSecret: 'YGiz9MTCZB0vyhnxPBuPxrjU1eTPOSzk',
			consumerKey: 'dMYmTugZ7mhlvDkx6EzrMU3dM7PysSPn'
		};
	}

	static stripeToken() {
		switch (this.getEnvironementType()) {
			case 'localhost':
			case 'development':
				return 'sk_test_UPxHNG267d0t3nacylmdAjSg00VJxtd84m';
			default:
				return 'sk_live_nYtwdqbV8DYP3LeEeIx1KHdX00H0ImulKJ';
		}
	}

	static stripePlans() {
		const plans = [
			{
				id: 'plan_GYJ48o3hnpxYRe',
				family: 'carte-coiffure',
				name: 'Abonnement Homme -35%',
				ivoire_id: '013',
				gender: 'male'
			},
			{
				id: 'plan_GYJ4mW3PvECqmk',
				family: 'carte-coiffure',
				name: 'Abonnement Femme -35%',
				ivoire_id: '012',
				gender: 'female'
			}
		];

		switch (this.getEnvironementType()) {
			case 'localhost':
			case 'development':
				plans[0].id = 'plan_GYHG3xfGejViNW';
				plans[1].id = 'plan_GYHG6sSc67U1kK';
				break;
			default:
				break;
		}

		return plans;
	}

	static stripeTvaTaxeCode() {
		let code = 'txr_1JopAGAYzfeTf54lqBcRDZRW';

		switch (this.getEnvironementType()) {
			case 'localhost':
			case 'development':
				code = 'txr_1JrsjXAYzfeTf54lO1BaFxo8';
				break;
			default:
				break;
		}

		return code;
	}
};
