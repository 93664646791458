import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { $ } from 'protractor';
import { GlobalForComponents } from '../global-for-components';
import { ApiService } from '../../services/api.service';

@Component({
	selector: 'app-user-validation',
	templateUrl: './user-validation.component.html',
	styleUrls: ['./user-validation.component.css']
})
export class UserValidationComponent implements OnInit {

	user: any;
	password: String;
	userId: string;
	years = [];
	months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
	days = [];
	acceptCoiffureDuMonde: boolean = false;
	showMoreCoiffureDuMonde: boolean = false;
	acceptNoChangeSexe: boolean = false;
	disableButton: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		public g: GlobalForComponents
	) { }

	ngOnInit() {
		const currentYear = parseInt(moment().format('YYYY'), 10);
		for (let year = currentYear; year > currentYear - 120; year--) {
			this.years.push(year);
		}
		for (let i = 1; i <= 31; i++) {
			this.days.push(i);
		}

		this.route.params.subscribe(async params => {
			this.userId = params['user_id'];
			if (this.userId) {
				this.getUser();
			} else {
				this.user = {
					email: null,
					gender: 'female',
					allow_reminder: false,
					allow_marketing_email: false,
					allow_marketing_sms: false,
					phone: {
						home: null,
						professional: null,
						mobile: null
					},
					address: {
						line1: null,
						line2: null,
						line3: null,
						city: null,
						postal_code: null
					},
					birthday_date: {
						day: null,
						month: null,
						year: null
					},
					name: {
						first: null,
						last: null
					},
					password: null
				};
			}
		});
	}

	changeGender(num: number) {
		if (num === 1) {
			this.user.gender = 'male';
		} else {
			this.user.gender = 'female';
		}
	}

	getUser() {
		return new Promise((resolve, reject) => {
			const promise: Promise<Object> = this.apiService.usersById(this.userId);
			promise.then(
				(data: any) => {
					const results = data.results;
					this.user = results.user;
					if (Number.isInteger(this.user.birthday_date)) {
						this.user.birthday_date = { year: null, month: null, day: null };
					} else if (!this.user.birthday_date) {
						this.user.birthday_date = { year: null, month: null, day: null };
					}
					if (this.user.phone && this.user.phone.home && !this.user.phone.mobile) {
						this.user.phone.mobile = this.user.phone.home;
					}
					this.user.allow_reminder = false;
					this.user.allow_marketing_email = false;
					this.user.allow_marketing_sms = false;
					resolve();
				},
				error => {
					this.g.setMessage(false, error.message);
					reject();
				}
			);
		});
	}

	edit() {
		this.disableButton = true;

		if (!this.user.phone.mobile) {
			if (confirm('Une validation manuelle sera faite en amont par un administrateur si vous n\'avez aucun numéro de téléphone à fournir. Souhaitez-vous continuer ?')) {
				// L'utilisateur accepte d'attendre la validation par un administrateur
				this.user.phone.mobile = '0000';
			}
		}

		if (this.user.name && this.user.name.last && this.user.name.first && this.user.phone && this.user.phone.mobile && this.user.address && this.user.address.postal_code && this.password && this.user.password && this.acceptCoiffureDuMonde && this.acceptNoChangeSexe) {
			let ok = true;

			if (this.user.password !== this.password) {
				ok = false;
				this.g.setMessage(false, 'Les mots de passes ne correspondent pas');
				this.disableButton = false;
			}

			if (ok) {
				let promise: Promise<Object>;
				if (this.userId) {
					promise = this.apiService.editUserForValidate(this.userId, this.user);
				} else {
					promise = this.apiService.createUserForValidate(this.user);
				}
				promise.then(
					(data: any) => {
						if (data.results.need_admin_for_validate === true) {
							this.g.setMessage(true, 'Vous devez attendre la validation d\'un administrateur');
							this.user = data.results.user;
							location.href = '/attente_admin/' + this.user._id;
						} else {
							this.g.setMessage(true, 'Parfait !');
							const hairdressingCard = data.results.hairdressing_card;
							location.href = '/votre-carte-coiffure/' + hairdressingCard;
						}
					},
					error => {
						this.g.setMessage(false, error.message);
						this.disableButton = false;
					}
				);
			}
		} else {
			this.g.setMessage(false, 'Veuillez remplir tous les champs obligatoires');
			this.disableButton = false;
		}
	}

}
