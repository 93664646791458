import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GlobalForComponents } from '../global-for-components';

@Component({
	selector: 'app-lost-password',
	templateUrl: './lost-password.component.html',
	styleUrls: ['./lost-password.component.css']
})
export class LostPasswordComponent implements OnInit {

	phone: string;
	codeSent: boolean = false;
	code: string;
	password: string;
	passwordConfirm: string;

	constructor(private apiService: ApiService, public g: GlobalForComponents) {
	}

	ngOnInit() {
	}

	sendCode() {
		const promise: Promise<Object> = this.apiService.lostPasswordSendCode(this.phone);
		promise.then((data: any) => {
			this.codeSent = true;
		}, (error) => {
			this.g.setMessage(false, error.message);
		});
	}

	change() {
		if (this.code && this.password) {
			if (this.password === this.passwordConfirm) {
				const promise: Promise<Object> = this.apiService.lostPasswordChange(this.code, this.password);
				promise.then((data: any) => {
					this.g.setMessage(true, 'Votre mot de passe a bien été changé');
					setTimeout(() => {
						location.href = '/se-connecter';
					}, 3000);
				}, (error) => {
					this.g.setMessage(false, error.message);
				});
			} else {
				this.g.setMessage(false, 'Les deux mots de passe ne correspondent pas');
			}
		}
	}

}
