import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-account-sign-out',
	templateUrl: './account-sign-out.component.html',
	styleUrls: ['./account-sign-out.component.css']
})
export class AccountSignOutComponent implements OnInit {

	constructor() { }

	ngOnInit() {
		localStorage.clear();
		location.href = '/';
	}

}
