import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
const GlobalVar = require('../../../global_vars');

@Injectable()
export class AuthService {

	url: String;
	token: String;

	constructor(private http: Http) {
		this.url = GlobalVar.getHost() + '/api/auth';

		if (typeof (localStorage) !== 'undefined') {
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token');
			}
		}
	}

	login(identifier: string, password: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/login', { identifier: identifier, password: password }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	isConnected(): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/is-connected', { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

}
