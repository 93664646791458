import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { $ } from 'protractor';
import { GlobalForComponents } from '../../global-for-components';
import { AccountService } from '../../../services/account.service';

@Component({
	selector: 'app-account-edit-personal-info',
	templateUrl: './account-edit-personal-info.component.html',
	styleUrls: ['./account-edit-personal-info.component.css']
})
export class AccountEditPersonalInfoComponent implements OnInit {

	user: any;
	password: string;
	passwordConfirm: string;
	years = [];
	months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
	days = [];

	constructor(
		private accountService: AccountService,
		public g: GlobalForComponents
	) { }

	ngOnInit() {
		const currentYear = parseInt(moment().format('YYYY'), 10);
		for (let year = currentYear; year > currentYear - 120; year--) {
			this.years.push(year);
		}
		for (let i = 1; i <= 31; i++) {
			this.days.push(i);
		}

		this.getMe();
	}

	getMe() {
		return new Promise((resolve, reject) => {
			const promise: Promise<Object> = this.accountService.getMe();
			promise.then(
				(data: any) => {
					const results = data.results;
					this.user = results.user;
					resolve();
				},
				error => {
					this.g.setMessage(false, error.message);
					reject();
				}
			);
		});
	}

	edit() {
		if (this.user.lastname && this.user.firstname && this.user.phone_mobile && this.user.address1 && this.user.city && this.user.zip_code) {
			let ok = true;

			if (this.password && this.password !== this.passwordConfirm) {
				ok = false;
				this.g.setMessage(false, 'Les mots de passes ne correspondent pas');
			}

			if (ok) {
				const promise = this.accountService.editUser(this.user, this.password);
				promise.then(
					(data: any) => {
						if (data.results.phone_validation_sent) {
							this.g.setMessage(true, 'Vos informations ont été modifiés mais vous devez confirmer votre nouveau numéro de téléphone.');
							setInterval(() => {
								location.href = 'compte/confirmer-telephone-mobile';
							}, 4000);
						} else {
							this.g.setMessage(true, 'Vos informations ont été modifiés');
						}
					},
					error => {
						this.g.setMessage(false, error.message);
					}
				);
			}
		} else {
			this.g.setMessage(false, 'Veuillez remplir tous les champs obligatoires');
		}
	}

}
