import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import * as moment from 'moment';
import { GlobalForComponents } from '../../global-for-components';

@Component({
	selector: 'app-account-register',
	templateUrl: './account-register.component.html',
	styleUrls: ['./account-register.component.css']
})
export class AccountRegisterComponent implements OnInit {

	user: any = { name: {}, gender: 'male' };
	password: String;

	constructor(private authService: AuthService, public g:GlobalForComponents) {
		if (typeof (localStorage) !== 'undefined') {
			if (localStorage.getItem('token')) {
				location.href = '/compte';
			}
		}
	}

	ngOnInit() {
	}

	changeGender(num: Number) {
		if (num == 1) {
			this.user.gender = 'male';
		} else {
			this.user.gender = 'female';
		}
	}

	changeLifeStade(num: Number) {
		if (this.user.birthday_date == undefined || this.user.birthday_date == '0001-01-01') {
			if (num == 2) {
				this.user.life_stade = 'child';
			} else if (num == 3) {
				this.user.life_stade = 'teenager';
			} else if (num == 4) {
				this.user.life_stade = 'adult';
			} else if (num == 5) {
				this.user.life_stade = 'senior';
			}
		}
	}

	changeDate(e) {
		var date = moment(e);

		var years = moment().diff(date, 'years');
		if (years < 2) {
			this.user.life_stade = 'baby';
		} else if (years < 12) {
			this.user.life_stade = 'child';
		} else if (years < 18) {
			this.user.life_stade = 'teenager';
		} else if (years < 70) {
			this.user.life_stade = 'adult';
		} else if (years >= 70) {
			this.user.life_stade = 'senior';
		}
	}

	register() {
		if (this.user.name && this.user.name.first && this.user.name.last && this.user.email && this.user.password) {
			if (this.user.password == this.password) {
				// var promise: Promise<Object> = this.authService.register(this.user);
				// promise.then((data) => {
				// 	if (data['success']) {
				// 		location.href = '/se-connecter';
				// 	} else {
				// 		this.g.setMessage(data['success'], data['error']['msg']);
				// 	}
				// }, (error) => {
				// 	this.g.setMessage(false, error.message);
				// });
			} else {
				this.g.setMessage(false, "Les mots de passes ne correspondent pas");
			}
		} else {
			this.g.setMessage(false, 'Veuillez remplir tous les champs obligatoires');
		}
	}

}
