import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { GlobalForComponents } from './components/global-for-components';
import { AuthService } from './services/auth.service';
const GlobalVar = require('../../global_vars');

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {

	token: String;
	year: Number;
	constructor(private router: Router, public g: GlobalForComponents, public authService: AuthService) {
		const date = new Date();
		this.year = date.getFullYear();

		if (typeof (localStorage) !== 'undefined') {
			this.token = localStorage.getItem('token');
		}

		this.router.events.subscribe((event) => {
			if (typeof (window) !== 'undefined') {
				// console.log(event);
				window.scrollTo(0, 0);
			}

			if (event instanceof NavigationStart) {
				this.g.hideMesssage();
				let skip_jwt = true;
				if (event.url.slice(0, 7) === '/compte') {
					skip_jwt = false;
				}

				if (!skip_jwt) {
					const promise: Promise<Object> = this.authService.isConnected();
					promise.then((data) => { }).catch(error => {
						if (typeof (localStorage) !== 'undefined') {
							this.g.setMessage(false, error.message);
							localStorage.clear();
							location.href = '/se-connecter';
						}
					});
				}
			}
		});

	}
}
