import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
const GlobalVar = require('../../../global_vars');

@Injectable()
export class AccountService {

	url: String;
	token: String;

	constructor(private http: Http) {
		this.url = GlobalVar.getHost() + '/api/account';

		if (typeof (localStorage) !== 'undefined') {
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token');
			}
		}
	}

	getMe(): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/me', { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	editUser(user: any, password: string): Promise<Object> {
		const url = this.url + '/user';

		return new Promise((resolve, reject) => {
			this.http.put(url, { user: user, new_password: password }, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	confirmMobilePhone(code: string): Promise<Object> {
		const url = this.url + '/confirm-mobile-phone';

		return new Promise((resolve, reject) => {
			this.http.post(url, { code: code }, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	getHiddenColumnStatDataTable(id?: string): Promise<Object> {
		let url = this.url + '/hidden-column-stat-data-table';
		if (id) { url += '/' + id; }

		return new Promise((resolve, reject) => {
			this.http.get(url, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	editHiddenColumnStatDataTable(id: string, categoryName: string, columns: Array<String>, isDefault: Boolean): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/hidden-column-stat-data-table/', { category_id: id, category_name: categoryName, columns: columns, default: isDefault }, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	deleteHiddenColumnStatDataTable(id: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.delete(this.url + '/hidden-column-stat-data-table/' + id, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}
}
