import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../services/account.service';
import * as moment from 'moment';
import 'moment/locale/fr';
import { GlobalForComponents } from '../../global-for-components';
const JsBarcode = require('jsbarcode');

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

	user: any;
	age: Number;
	birthdayDate: String;
	hairdressingCard: string;
	creditLoyalty: String;
	privilegeName: String;
	privilegeExpirationDate: String;

	constructor(private accountService: AccountService, public g: GlobalForComponents) {
		moment.locale('fr');
		this.get();
	}

	ngOnInit() {
	}

	get() {
		const promise: Promise<Object> = this.accountService.getMe();
		promise.then((data: any) => {
			this.user = data.results.user;
			this.creditLoyalty = (this.user.credit_loyalty || '0.00').toString();

			if (this.user.birthday_day && this.user.birthday_month) {
				if (this.user.birthday_year) {
					const birthdayDate = moment(this.user.birthday_year + '-' + addZero(this.user.birthday_month) + '-' + addZero(this.user.birthday_day));
					this.birthdayDate = birthdayDate.format('dddd DD MMMM YYYY');
					this.age = moment().diff(birthdayDate, 'years');
				} else {
					this.birthdayDate = this.user.birthday_day + ' ' + moment().month(this.user.birthday_month).format('MMMM');
				}
			}

			this.hairdressingCard = this.user.card_id;
			setTimeout(() => {
				JsBarcode('#code128', this.hairdressingCard, {
					format: 'CODE128',
					background: 'transparent'
				});
			}, 500);

			switch (this.user.life_stade) {
				case 'baby':
					this.user.life_stade = 'Bébé';
					break;
				case 'child':
					this.user.life_stade = 'Enfant';
					break;
				case 'teenager':
					this.user.life_stade = 'Adolescent';
					break;
				case 'adult':
					this.user.life_stade = 'Adulte';
					break;
				case 'senior':
					this.user.life_stade = 'Senior';
					break;
				default:
					break;
			}

			switch (this.user.gender) {
				case 'male':
					this.user.gender = 'Homme';
					break;
				case 'female':
					this.user.gender = 'Femme';
					break;
				default:
					break;
			}

			this.privilegeName = null;
			switch (this.user.privilege_id) {
				case '996002':
					this.privilegeName = 'Privilège jeunes 12-24 ans';
					break;
				case '996005':
					this.privilegeName = 'Privilège commercant';
					break;
				case '996007':
					this.privilegeName = 'Privilège Collectivités / Entreprises / CE';
					break;
				case '996008':
					this.privilegeName = 'Privilège decouverte';
					break;
				case '996010':
					this.privilegeName = 'Privilège abonnement 6 mois 50%';
					break;
				case '996011':
					this.privilegeName = 'Abonnement privilège';
					break;
				case '996012':
					this.privilegeName = 'Abonnement Femme -35%';
					break;
				case '996013':
					this.privilegeName = 'Abonnement Homme -35%';
					break;
				default:
					break;
			}
			if (this.privilegeName != null) {
				if (this.user.privilege_expiration_date != null) {
					this.privilegeExpirationDate = moment(this.user.privilege_expiration_date).format('dddd DD MMMM YYYY');
				}
			}
		}, (error) => {
			this.g.setMessage(false, error.message);
		});

		function addZero(number, length = 2) {
			let my_string = '' + number;
			while (my_string.length < length) {
				my_string = '0' + my_string;
			}

			return my_string;
		}
	}
}
