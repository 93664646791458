import { Component, OnInit } from '@angular/core';
import { GlobalForComponents } from '../../global-for-components';
import { ApiService } from '../../../services/api.service';
import { AccountService } from '../../../services/account.service';
import { StripeService } from '../../../services/stripe.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

declare var Stripe;
const stripe = Stripe('pk_live_ArWFhYv1MiVOxtdjO17uJj0Z00PPKJ9VTo');
// const stripe = Stripe('pk_test_RZsgORW7gKQITUWOcvGyOHZt00ltHNVQ9k');

@Component({
	selector: 'app-subscribe',
	templateUrl: './subscribe.component.html',
	styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent implements OnInit {

	buttonDisabled: Boolean;
	chosenPlan: number = 1;
	plans: Array<any> = [
		{ name: 'Abonnement Homme -35%', price: '4.90', tva: '0.82' },
		{ name: 'Abonnement Femme -35%', price: '9.90', tva: '1.65' }
	];

	salons: Array<any>;
	hairdresserEmployees: Array<any>;
	salonId: number = null;
	hairdresserEmployeeId: string = null;
	paidInvoiceUrl: string = null;
	userPaymentMethods: Array<any> = [];
	paymentMethodId: string = null;
	acceptCGV: boolean = false;

	constructor(public g: GlobalForComponents, private router: Router, private apiService: ApiService, private accountService: AccountService, private stripeService: StripeService) { }

	ngOnInit() {
		this.getSalons();
		this.getUser();
		this.getUserPaymentMethods();

		const promise: Promise<Object> = this.stripeService.subscriptions();
		promise.then(
			(data: any) => {
				const results = data.results;
				if (results.subscriptions.length > 0) {
					this.g.setSnackbar('Vous avez déjà un abonnement en cours, veuillez attendre qu\'il se termine', 7);
					this.router.navigateByUrl('/compte/mes-abonnements');
				}
			},
			error => {
				this.g.setMessage(false, error.message);
			}
		);
	}

	getUser() {
		const promise: Promise<Object> = this.accountService.getMe();
		promise.then((data: any) => {
			const user = data.results.user;

			if (user.gender === 'female') {
				this.chosenPlan = 2;
			}
		}, (error) => {
			this.g.setMessage(false, error.message);
		});
	}

	getUserPaymentMethods() {
		const promise: Promise<Object> = this.stripeService.paymentMethods();
		promise.then((data: any) => {
			this.userPaymentMethods = data.results.payment_methods;
			if (this.userPaymentMethods[0]) {
				this.paymentMethodId = this.userPaymentMethods[0].id;
			}
		}, (error) => {
			this.g.setMessage(false, error.message);
		});
	}

	getSalons() {
		const promise: Promise<Object> = this.apiService.salons();
		promise.then(
			(data: any) => {
				this.salons = data.results.salons;
			},
			error => {
				this.g.setMessage(false, error.message);
			}
		);
	}

	getHairdresserEmployees(salonId) {
		this.hairdresserEmployeeId = null;

		const promise: Promise<Object> = this.apiService.hairdresserEmployees(salonId);
		promise.then(
			(data: any) => {
				this.hairdresserEmployees = data.results.hairdresser_employees;
			},
			error => {
				this.g.setMessage(false, error.message);
			}
		);
	}

	async subscribe() {
		this.buttonDisabled = true;
		this.g.reinit();

		if (this.acceptCGV) {
			if (this.salonId && this.hairdresserEmployeeId) {
				sessionStorage.setItem('subscription-salonId', this.salonId.toString());
				sessionStorage.setItem('subscription-hairdresserEmployeeId', this.hairdresserEmployeeId);

				const promise = this.stripeService.createCheckoutSessionForSubscription();
				promise.then(
					async (data: any) => {
						const stripeSessionId = data.results.id;
						const { error } = await stripe.redirectToCheckout({ sessionId: stripeSessionId });
						if (error) {
							this.buttonDisabled = false;
							this.g.setMessage(false, error.message);
						}
					},
					error => {
						this.g.setMessage(false, error.message);
					}
				);
			} else {
				this.buttonDisabled = false;
				this.g.setMessage(false, 'Veuillez choisir votre coiffeur partenaire');
			}
		} else {
			this.buttonDisabled = false;
			this.g.setMessage(false, 'Merci d\'accepter les conditions générales de vente avant de continuer');
		}
	}

}
