import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
const GlobalVar = require('../../../global_vars');

@Injectable({
	providedIn: 'root'
})
export class StripeService {

	url: String;
	token: String;

	constructor(private http: Http) {
		this.url = GlobalVar.getHost() + '/api/stripe';

		if (typeof (localStorage) !== 'undefined') {
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token');
			}
		}
	}

	subscribe(stripeSessionId: string, originSalon: string, originHairdresserEmployee: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/subscribe/' + stripeSessionId, {
				origin_salon: originSalon,
				origin_hairdresser_employee: originHairdresserEmployee
			}, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	subscriptions(): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/subscriptions', { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	cancelSubscription(id: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.delete(this.url + '/subscription/' + id, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	renewSubscription(id: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/renew-subscription/' + id, {}, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	paymentMethods(): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/payment-methods', { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	addPaymentMethod(paymentMethodId: any): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/payment-method', { payment_method_id: paymentMethodId }, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	setDefaultPaymentMethod(paymentMethodId: any): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.put(this.url + '/set-default-payment-method', { payment_method_id: paymentMethodId }, { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	createCheckoutSessionForSubscription(): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/create-checkout-session-for-subscription', { headers: new Headers({ 'x-access-token': this.token }) }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

}
