import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { GlobalForComponents } from '../global-for-components';

@Component({
	selector: 'app-search-user-for-validate',
	templateUrl: './search-user-for-validate.component.html',
	styleUrls: ['./search-user-for-validate.component.css']
})
export class SearchUserForValidateComponent implements OnInit {

	hairdressingCard: string;
	lastname: string;
	firstname: string;
	disableButton: boolean;

	constructor(private apiService: ApiService, public g: GlobalForComponents) { }

	ngOnInit() {
	}

	search() {
		this.disableButton = true;
		const promise: Promise<Object> = this.apiService.users(this.hairdressingCard, this.lastname, this.firstname);
		promise.then((data: any) => {
			location.href = '/valider/' + data.results.user_progressaubryacademie_id;
		}, (error) => {
			this.disableButton = false;
			this.g.setMessage(false, error.message);
		});
	}

	goToLogin() {
		if (this.hairdressingCard) {
			location.href = '/se-connecter/' + this.hairdressingCard;
		} else {
			location.href = '/se-connecter';
		}
	}

}
