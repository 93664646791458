import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { GlobalForComponents } from '../../global-for-components';
const JsBarcode = require('jsbarcode');

@Component({
	selector: 'app-generate-publicity-document',
	templateUrl: './generate-publicity-document.component.html',
	styleUrls: ['./generate-publicity-document.component.css']
})
export class GeneratePublicityDocumentComponent implements OnInit {

	codes = [];
	numberToGenerate: number = 0;
	remaining: number = 0;
	password: string;

	constructor(private apiService: ApiService, public g: GlobalForComponents) {
	}

	ngOnInit() {
		this.numberToGenerate = 2;
		this.password = 'm;2&xtwke:Np';
		this.generate();
	}

	generate() {
		if (this.numberToGenerate > 0) {
			const promise: Promise<Object> = this.apiService.generatePublicityDocuments(this.numberToGenerate, this.password);

			this.numberToGenerate = 0;
			this.g.errorMsg = '';
			this.g.successMsg = '';

			promise.then((data: any) => {
				this.codes = data.results.codes;
				this.remaining = data.results.remaining;

				setTimeout(() => {
					this.codes.forEach(code => {
						JsBarcode(`#code128-${code.code}`, code.code, {
							format: 'CODE128',
							background: 'transparent',
							width: 3,
							height: 70
						});
					});
				}, 500);
			}, (error) => {
				this.g.setMessage(false, error.message);
			});

		} else {
			this.g.setMessage(false, 'Veuillez choisir au moins 1 code à générer');
		}
	}

}
