import { Component, OnInit } from '@angular/core';
import { GlobalForComponents } from '../../global-for-components';
import { AdminService } from '../../../services/admin.service';

@Component({
  selector: 'app-users-validation',
  templateUrl: './users-validation.component.html',
  styleUrls: ['./users-validation.component.css']
})
export class UsersValidationComponent implements OnInit {

	users: Array<any> = [];

	constructor(public g: GlobalForComponents, private adminService: AdminService) { }

	ngOnInit() {
		this.getUsers();
		setInterval(() => {
			this.getUsers();
		}, 180000/*3 minute*/);
	}

	getUsers() {
		const promise: Promise<Object> = this.adminService.usersValidation();
		promise.then((data: any) => {
			this.users = data.results.users;
		}, (error) => {
			this.g.setMessage(false, error.message);
		});
	}

	accept(userId: string) {
		const promise: Promise<Object> = this.adminService.userValidation(userId);
		promise.then((data: any) => {
			this.getUsers();
		}, (error) => {
			this.g.setMessage(false, error.message);
		});
	}

	decline(userId: string) {
		const promise: Promise<Object> = this.adminService.declineUserValidation(userId);
		promise.then((data: any) => {
			this.getUsers();
		}, (error) => {
			this.g.setMessage(false, error.message);
		});
	}

}
