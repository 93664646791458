import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalForComponents } from '../global-for-components';
import { ApiService } from '../../services/api.service';
const JsBarcode = require('jsbarcode');

@Component({
	selector: 'app-wait-admin-for-user-validation',
	templateUrl: './wait-admin-for-user-validation.component.html',
	styleUrls: ['./wait-admin-for-user-validation.component.css']
})
export class WaitAdminForUserValidationComponent implements OnInit {

	userId: string;
	hairdressingCard: string;
	interval: any;
	showLoading: boolean = true;

	constructor(
		private route: ActivatedRoute,
		private apiService: ApiService,
		public g: GlobalForComponents
	) { }

	ngOnInit() {
		this.route.params.subscribe(async params => {
			this.userId = params['user_id'];
			this.pending();
			this.interval = setInterval(() => {
				this.pending();
			}, 10000);
		});
	}

	pending() {
		const promise: Promise<Object> = this.apiService.pendingUserValidationByAdmin(this.userId);
		promise.then(
			(data: any) => {
				if (data.results.hairdressing_card) {
					clearInterval(this.interval);
					this.showLoading = false;
					this.g.setMessage(true, 'Compte validé ! Votre Carte Coiffure est maintenant activée');
					this.hairdressingCard = data.results.hairdressing_card;
					setTimeout(() => {
						JsBarcode('#code128', this.hairdressingCard);
					}, 500);
				} else if (data.results.user_already_exist === true) {
					clearInterval(this.interval);
					this.showLoading = true;
					this.g.setMessage(true, 'Compte déjà validé. Veuillez vous connecter');
				}
			},
			error => {
				clearInterval(this.interval);
				this.showLoading = false;
				this.g.setMessage(false, error.message);
			}
		);
	}

}
