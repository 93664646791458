import { Component, OnInit } from '@angular/core';
import { StripeService } from '../../../services/stripe.service';
import { GlobalForComponents } from '../../global-for-components';
import { AccountService } from '../../../services/account.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-account-subscriptions',
	templateUrl: './account-subscriptions.component.html',
	styleUrls: ['./account-subscriptions.component.css']
})
export class AccountSubscriptionsComponent implements OnInit {

	subscriptions: Array<any>;

	constructor(public g: GlobalForComponents, private accountService: AccountService, private stripeService: StripeService, private route: ActivatedRoute, private router: Router) {
		moment.locale('fr');
	}

	ngOnInit() {
		this.getSubscriptions();

		this.route.params.subscribe(params => {
			if (params['stripe_session_id']) {
				const originSalon = sessionStorage.getItem('subscription-salonId');
				const originHairdresserEmployee = sessionStorage.getItem('subscription-hairdresserEmployeeId');
				const promise: Promise<Object> = this.stripeService.subscribe(params['stripe_session_id'], originSalon, originHairdresserEmployee);
				promise.then(
					(data: any) => {
						this.router.navigateByUrl('/compte/mes-abonnements');
					},
					error => {
						this.g.setMessage(false, error.message);
					}
				);
			}
		});
	}

	getSubscriptions() {
		return new Promise((resolve, reject) => {
			const promise: Promise<Object> = this.stripeService.subscriptions();
			promise.then(
				(data: any) => {
					const results = data.results;
					this.subscriptions = results.subscriptions;
					resolve(true);
				},
				error => {
					if (error.error.http_status_code === 401) {
					} else {
						this.g.setMessage(false, error.message);
					}
					reject();
				}
			);
		});
	}

	cancelSubscription(id: string) {
		if (confirm('Souhaitez-vous vraiment annuler votre abonnement ? Celui-ci prendra fin à la date d\'échéance')) {
			const promise: Promise<Object> = this.stripeService.cancelSubscription(id);
			promise.then(
				(data: any) => {
					this.g.setMessage(true, 'Votre abonnement prendra fin à l\'échéance');
					this.getSubscriptions();
				},
				error => {
					this.g.setMessage(false, error.message);
				}
			);
		}
	}

	renewSubscription(id: string) {
		const promise: Promise<Object> = this.stripeService.renewSubscription(id);
		promise.then(
			(data: any) => {
				this.g.setMessage(true, 'Votre abonnement a été réactivé, vous serez prélevé comme d\'habitude');
				this.getSubscriptions();
			},
			error => {
				this.g.setMessage(false, error.message);
			}
		);
	}

}
