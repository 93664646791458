import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
const GlobalVar = require('../../../global_vars');

@Injectable()
export class ApiService {

	url: String;
	token: String;

	constructor(private http: Http) {
		this.url = GlobalVar.getHost() + '/api';

		if (typeof (localStorage) !== 'undefined') {
			if (localStorage.getItem('token')) {
				this.token = localStorage.getItem('token');
			}
		}
	}

	users(hairdressingCard: string, lastname: string, firstname: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/user/' + hairdressingCard + '/' + lastname + '/' + firstname).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	usersById(progressAubryAcademieId: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/user/' + progressAubryAcademieId).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	editUserForValidate(progressAubryAcademieId: string, user: any): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.put(this.url + '/user/' + progressAubryAcademieId, user).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	createUserForValidate(user: any): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/user', user).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	confirmSms(userId: string, code: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/confirm-sms/' + userId + '/' + code, {}).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	salons(): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/salons').subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	hairdresserEmployees(salonId: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/hairdresser_employees/' + salonId).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	lostPasswordSendCode(phone: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/lost-password/send-code', { phone: phone }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	lostPasswordChange(code: string, password: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/lost-password/change', { code: code, password: password }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	pendingUserValidationByAdmin(userId: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.get(this.url + '/pending-user-validation-by-admin/' + userId).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

	generatePublicityDocuments(number: number, password: string): Promise<Object> {
		return new Promise((resolve, reject) => {
			this.http.post(this.url + '/generate-publicity-documents', { number_to_generate: number, password: password }).subscribe(data => {
				if (data.json()) {
					resolve(data.json());
				} else {
					reject({});
				}
			}, error => {
				reject(error.json());
			});
		});
	}

}
